import { useState } from "react";
import axios from "axios";
import updateAssets from "./scripts/updateAssets.mjs";
import "./App.css";
import env from "./env-name.json";

const envName = env.envName,
  orgId = env.orgId,
  authToken = env.publicToken;

const App = () => {
  const [exeMessage, setExeMessage] = useState("");
  function checkMaterial(e) {
    e.preventDefault();
    const opt = {
      action: "active-material",
    };
    axios.post("/checkmaterials", { envName, orgId, authToken, opt });
    setExeMessage(
      "Update Sent! Please wait a couple of minutes to check the material items."
    );
  }

  return (
    <div className="App">
      <h1>
        Click the button to start checking the materials if they don't have
        asset 'active' tag will be removed
      </h1>
      <input
        type="button"
        className="button"
        value="Start"
        onClick={checkMaterial}
      ></input>
      <div>
        <p>{exeMessage}</p>
      </div>
    </div>
  );
};

export default App;
